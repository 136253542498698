import { CQLoginClient, CQQuizClient } from '../clients';
import { APP_CONFIG } from '../config';
import { createAppStore } from './app';
import { createQuizStore } from './quiz';
import { createSessionStore } from './session';
import { createProctorStore } from './ai-proctor';

export * from './app';
export * from './session';
export * from './quiz';
export * from './ai-proctor';

const cqQuizClient = new CQQuizClient({
	quizServerURL: APP_CONFIG.QuizServerURL,
	staticStorageApiURL: APP_CONFIG.StaticStorageApiURL,
	socketServerURL: APP_CONFIG.SocketServerURL,
});

const cqLoginClient = new CQLoginClient({
	loginServiceURL: APP_CONFIG.LoginServiceURL,
});

export const useAppStore = createAppStore(cqQuizClient);
export const useSessionStore = createSessionStore({ cqQuizClient, cqLoginClient });
export const useQuizStore = createQuizStore(cqQuizClient);
export const useAiProctorStore = createProctorStore(cqQuizClient);
