import { EventEmitter } from 'events';
import TypedEventEmitter from 'typed-emitter';
import { FaceDetectorResult, FaceLandmarkerResult, ObjectDetectorResult } from '../../@types/mediapipe';

export interface DetectionResult {
	isUserPresent: boolean
	imageBitMap: ImageBitmap | null,
	faceDetection: FaceDetectorResult | null,
	objectDetection: ObjectDetectorResult | null,
	drawnImage?: string,
}

export interface SupportedEvents {
	'result': (result: DetectionResult) => void,
	'initComplete': () => void,
	'error': (error: Error) => void,
}

export class ProctorWebWorkers extends (
	EventEmitter as new () => TypedEventEmitter<SupportedEvents>
) {
	private isInitComplete = false;

	private currentCount = 0;

	private workerFarm: Array<Worker> = [];

	private activeWebWorkers: Array<Worker> = [];

	constructor(numberOfWebWorkers: number) {
		super();
		for (let index = 0; index < numberOfWebWorkers; index += 1) {
			const worker = new Worker('/worker/object-detection-worker.js');
			this.attachHandlers(worker, index);
			this.workerFarm.push(worker);
		}
	}

	private attachHandlers(worker: Worker, workerIndex: number) {
		// eslint-disable-next-line no-param-reassign
		worker.onerror = (ev) => {
			ProctorWebWorkers.handleWorkerError(workerIndex, ev);
		};
		// eslint-disable-next-line no-param-reassign
		worker.onmessageerror = window.onerror;
		// eslint-disable-next-line no-param-reassign
		worker.onmessage = (ev) => {
			this.handleWebWorkerResponse(workerIndex, ev);
		};
	}

	handleWebWorkerResponse(index: number, ev: MessageEvent) {
		const { data } = ev;
		if (typeof data === 'string') {
			if (data === 'init-complete') {
				this.handleInitComplete(index);
				return;
			}
			return;
		}
		try {
			if ('detectionResult' in data) {
				this.emit('result', data.detectionResult);
			}
		} catch (error) {
			console.error(error);
		}
	}

	static handleWorkerError(index: number, ev: ErrorEvent) {
		console.error(`Worker${index} Faces Some Error: `, ev);
	}

	handleInitComplete(index: number) {
		this.activeWebWorkers.push(this.workerFarm[index]);
		if (this.isInitComplete) {
			return;
		}
		this.isInitComplete = true;
		this.emit('initComplete');
	}

	detect(imageToSend: ImageBitmap) {
		const activeWorker = this.activeWebWorkers?.[this.currentCount];
		if (!activeWorker) {
			return false;
		}
		activeWorker.postMessage(imageToSend, [imageToSend]);
		this.currentCount = (this.currentCount + 1) % this.activeWebWorkers.length;
		// console.log('New Worker Selected', this.currentCount);
		return true;
	}

	terminate() {
		this.workerFarm.forEach((worker) => {
			console.info('Worker destroied');
			worker.terminate();
		});
	}
}
