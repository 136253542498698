import React, {
	useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
	Button, Collapse, CollapseProps, message, Popconfirm, Table, Tooltip,
} from 'antd';
import { CaretRightOutlined, CheckCircleFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { DashboardContentType, QuestionStatus } from '../../config';
import { logger } from '../../libs/utils/logger';

import { ReactComponent as LockIcon } from '../../assets/icons/lock-icon.svg';

import './dashboard-segment.scoped.css';

interface DashboardSegmentProps {
	quizName: string
	segmentIndex: number
	segmentData: QuizDashboardSegmentData
	showSubmit: boolean
	sendSubmitButtonEvent: (data: SubmissionPayload) => void,
	style?: React.CSSProperties,
	onSubmit: (segmentIndex: number) => Promise<void>,
}

export const DashboardSegment: React.FunctionComponent<DashboardSegmentProps> = (props) => {
	const {
		quizName, segmentData, segmentIndex, showSubmit, style, onSubmit, sendSubmitButtonEvent,
	} = props;

	const segmentSubmissionButtonPressTime = useRef<string>('');

	useEffect(() => {
		segmentSubmissionButtonPressTime.current = '';
	}, [showSubmit]);

	const popUpMessageForQuizSubmission = useMemo(() => (
		<p>
			Do you really want to submit
			{' '}
			<span style={{ color: '#de6834' }}>{segmentData.title}</span>
			{'  '}
			segment?
			<br />
			You won&apos;t be able to submit questions from this segment again.
		</p>
	), [segmentData]);

	const columns = useMemo(() => [
		{
			className: 'serial-number',
			width: '7%',
			title: 'Sr. no.',
			render: (text: unknown, record: unknown, index: number) => `${index + 1}.`,
		},
		{
			className: 'question-title',
			width: '50%',
			title: 'Question',
			dataIndex: 'title',
			key: 'title',
			render: (text: string, record: DashboardSegmentQuestionMeta) => (
				<Link
					to={(`/test/${quizName}/attempt/${record._id}`)}
				>
					{record.title}
				</Link>
			),
		},
		{
			className: 'question-type',
			width: '15%',
			title: 'Type',
			dataIndex: 'type',
			key: 'type',
			render: (text: number) => (
				DashboardContentType[text]
			),
		},
		{
			className: 'question-status',
			width: '15%',
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (text: number) => (
				QuestionStatus[text]
			),
		},
		{
			className: 'question-actions',
			width: '13%',
			title: 'Action',
			key: 'action',
			render: (text: string, record: DashboardSegmentQuestionMeta) => (
				<Link
					to={(`/test/${quizName}/attempt/${record._id}`)}
				>
					{
						record.status === QuestionStatus.Submitted
							? 'Modify Answer'
							: 'Solve Question'
					}
				</Link>
			),
		},
	], [quizName]);

	const submittedSectionColumns = useMemo(() => [
		{
			className: 'serial-number',
			width: '8%',
			title: 'Sr. no.',
			render: (text: unknown, record: unknown, index: number) => `${index + 1}.`,
		},
		{
			className: 'question-title',
			width: '60%',
			title: 'Question',
			dataIndex: 'title',
			key: 'title',
		},
		{
			className: 'question-type',
			width: '17%',
			title: 'Type',
			dataIndex: 'type',
			key: 'type',
			render: (text: number) => (
				DashboardContentType[text]
			),
		},
		{
			className: 'question-status',
			width: '15%',
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (text: number) => (
				text === 0 ? 'Not Submitted' : QuestionStatus[text]
			),
		},
	], []);

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [activeKeys, setActiveKeys] = useState<string | string[]>((segmentData.isDisabled || segmentData.isSubmitted) ? [] : [`${segmentData._id}-panel`]);

	const handleSubmitSegment = useCallback(async () => {
		try {
			if (sendSubmitButtonEvent) {
				sendSubmitButtonEvent({
					segmentSubmissionPressTime: segmentSubmissionButtonPressTime.current,
					yesButtonPressTime: new Date().toString(),
					index: segmentIndex,
				});
			}
			setIsSubmitting(true);
			await onSubmit(segmentIndex);
		} catch (e: any) {
			logger.error(e);

			message.error(e.message);
		} finally {
			setIsSubmitting(false);
		}
	}, [segmentIndex, onSubmit, sendSubmitButtonEvent]);

	const toggleSegment = useCallback(() => {
		if (activeKeys.includes(`${segmentData._id}-panel`)) {
			setActiveKeys([]);
		} else {
			setActiveKeys(`${segmentData._id}-panel`);
		}
	}, [segmentData, activeKeys]);

	useEffect(() => {
		// auto open next segment on submit. it's for force rerendering
		if (!(segmentData.isSubmitted || segmentData.isDisabled)) {
			setActiveKeys(`${segmentData._id}-panel`);
		}
	}, [segmentData]);

	return (
		<div className={`dashboard-segment-container ${activeKeys.includes(`${segmentData._id}-panel`) ? 'active' : ''}`} style={style}>
			{
				(() => {
					if (segmentData.isSubmitted) {
						return (
							<Collapse
								className={`dashboard-segment submitted ${activeKeys.includes(`${segmentData._id}-panel`) ? 'active' : ''}`}
								activeKey={activeKeys}
								expandIcon={({ isActive }) => (
									<CaretRightOutlined
										style={{ color: '#fff' }}
										rotate={isActive ? 90 : 0}
										onClick={toggleSegment}
									/>
								)}
								expandIconPosition="right"
								collapsible={isSubmitting ? 'disabled' : 'header'}
								onChange={setActiveKeys}
							>
								<Collapse.Panel
									header={(
										<div className="segment-header">
											<div>
												<span>
													Section
													{' '}
													{String.fromCharCode(segmentIndex + 65)}
												</span>
												<span>
													{' - '}
													{segmentData.title}
												</span>
												<span>
													<CheckCircleFilled style={{ fontSize: 22 }} />
												</span>
											</div>
											<div>
												<span>
													{
														segmentData.questions.filter(
															(el) => el.status === QuestionStatus.Submitted,
														).length
													}
													/
													{segmentData.questions.length}
													{' '}
													Questions submitted successfully
												</span>
											</div>
										</div>
									)}
									key={`${segmentData._id}-panel`}
									style={{ position: 'relative' }}
								>
									<div className={`segment-data ${!showSubmit ? 'revisit-allowed' : ''}`}>
										<Table
											pagination={false}
											columns={submittedSectionColumns}
											dataSource={segmentData.questions}
											rowKey="_id"
										/>
									</div>
								</Collapse.Panel>
							</Collapse>
						);
					} if (segmentData.isDisabled) {
						return (
							<div className="dashboard-segment disabled">
								<Tooltip
									title="Submit previous section to unlock this section."
									arrowPointAtCenter
									placement="bottom"
									overlayStyle={{
										maxWidth: 'unset',
										fontFamily: 'Hind, sans-serif',
										fontSize: 13,
									}}
								>
									<div className="segment-header">
										<div>
											<span>
												Section
												{' '}
												{String.fromCharCode(segmentIndex + 65)}
											</span>
											<span>
												{' - '}
												{segmentData.title}
											</span>

											<span>
												<LockIcon />
											</span>
										</div>
										<div>
											<span>
												{segmentData.questions.length}
												{' '}
												Questions
											</span>
										</div>
									</div>
								</Tooltip>
							</div>
						);
					}

					return (
						<Collapse
							className={`dashboard-segment ${activeKeys.includes(`${segmentData._id}-panel`) ? 'active' : ''}`}
							activeKey={activeKeys}
							expandIcon={({ isActive }) => (
								<CaretRightOutlined
									style={{ color: '#fff' }}
									rotate={isActive ? 90 : 0}
									onClick={toggleSegment}
								/>
							)}
							expandIconPosition="right"
							collapsible={isSubmitting ? 'disabled' : 'header'}
							onChange={setActiveKeys}
						>
							<Collapse.Panel
								header={(
									<div className="segment-header">
										<div>
											<span>
												Section
												{' '}
												{String.fromCharCode(segmentIndex + 65)}
											</span>
											<span>
												{' - '}
												{segmentData.title}
											</span>
										</div>
										<div>
											<span>
												{
													segmentData.questions.filter(
														(el) => el.status === QuestionStatus.Submitted,
													).length
												}
												/
												{segmentData.questions.length}
												{' '}
												Questions
											</span>
										</div>
									</div>
								)}
								key={`${segmentData._id}-panel`}
								style={{ position: 'relative' }}
							>
								<div className={`segment-data ${!showSubmit ? 'revisit-allowed' : ''}`}>
									<Table
										pagination={false}
										columns={columns}
										dataSource={segmentData.questions}
										rowKey="_id"
									/>
									{
										showSubmit && (
											<Popconfirm
												title={popUpMessageForQuizSubmission}
												onConfirm={handleSubmitSegment}
												okText="yes"
												cancelText="no"
												getPopupContainer={(trigger) => trigger.parentElement || document.body}
											>
												<Button
													type="primary"
													size="large"
													loading={isSubmitting}
													onClick={(ev) => {
														segmentSubmissionButtonPressTime.current = new Date().toString();
													}}
												>
													submit this section
												</Button>
											</Popconfirm>
										)
									}
								</div>
							</Collapse.Panel>
						</Collapse>
					);
				})()
			}
		</div>
	);
};
