const hostNameTOServerName: { [key: string]: {
	MainServerURL: string, LoginServiceURL: string,
	QuizServerURL: string, StaticStorageApiURL: string,
	SocketServerURL: string,
} | undefined } = {
	'tests.cqtestga.com': {
		MainServerURL: 'https://course.cqtestga.com',
		LoginServiceURL: 'https://login.cqtestga.com',
		QuizServerURL: 'https://cqtestga.com',
		SocketServerURL: 'https://cqtestga.com',
		StaticStorageApiURL: 'https://static.cqtestga.com',
	},
	'tests.supercoders.tech': {
		MainServerURL: 'https://course.supercoders.tech',
		LoginServiceURL: 'https://login.supercoders.tech',
		QuizServerURL: 'https://assess.supercoders.tech',
		SocketServerURL: 'https://assess.supercoders.tech',
		StaticStorageApiURL: 'https://static-supercoder.cqtestga.com',
	},
	'exam.testpad.chitkara.edu.in': {
		MainServerURL: 'https://course.testpad.chitkara.edu.in',
		LoginServiceURL: 'https://login.testpad.chitkara.edu.in',
		QuizServerURL: 'https://assess.testpad.chitkara.edu.in',
		SocketServerURL: 'https://assess.testpad.chitkara.edu.in',
		StaticStorageApiURL: 'https://static.assess.testpad.chitkara.edu.in',
	},
	'exam.testpad.chitkarauniversity.edu.in': {
		MainServerURL: 'https://course.testpad.chitkarauniversity.edu.in',
		LoginServiceURL: 'https://login.testpad.chitkarauniversity.edu.in',
		QuizServerURL: 'https://assess.testpad.chitkarauniversity.edu.in',
		SocketServerURL: 'https://assess.testpad.chitkarauniversity.edu.in',
		StaticStorageApiURL: 'https://static.assess.testpad.chitkarauniversity.edu.in',
	},
};

let config = hostNameTOServerName[window.location.hostname];

if (!config) {
	config = {
		MainServerURL: process.env.REACT_APP_MAIN_SERVER_URL as string,
		QuizServerURL: process.env.REACT_APP_QUIZ_SERVER_URL as string,
		SocketServerURL: process.env.REACT_APP_SOCKET_SERVER_URL as string,
		LoginServiceURL: process.env.REACT_APP_LOGIN_SERVER_URL as string,
		StaticStorageApiURL: process.env.REACT_APP_STATIC_STORAGE_API_URL as string,
	};
}

export const APP_CONFIG: AppConfig = {
	MainServerURL: config.MainServerURL,
	QuizServerURL: config.QuizServerURL,
	SocketServerURL: config.SocketServerURL,
	LoginServiceURL: config.LoginServiceURL,
	StaticStorageApiURL: config.StaticStorageApiURL,
	CaptiaKey: process.env.REACT_APP_CAPTIA_KEY as string,
	isChitkara: (process.env.REACT_APP_Chitkara as string) === '1',
	LiveStreamPublicApiKey: (process.env.REACT_APP_JITSI_PUBLIC_API_KEY as string),
	ElectronScheme: ((process.env.REACT_APP_Chitkara as string) === '1') ? 'testpad-chitkara' : 'test-codequotient',
	EnableProctorNotation: (process.env.REACT_APP_ENABLED_PROCTOR_NOTATION === '1'),
};

export const isElectronApp:boolean = ('api' in window);

export const API_PATHS = {};

export * from './constants';
