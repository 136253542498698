import {
	Button, Divider, Form, message, Modal, Tree, Upload,
} from 'antd';
import type { UploadFile } from 'antd';
import React, {
	useCallback, useEffect, useRef, useState,
} from 'react';
import JSZip from 'jszip';
import { UploadOutlined, CloseCircleFilled } from '@ant-design/icons';
import ReactQuill from 'react-quill';

import './attempt-subjective.scoped.css';
import './attempt-subjective.css';
import 'react-quill/dist/quill.snow.css';

const typeOfZipFormat = ['application/zip', 'application/x-zip-compressed'];

interface EditorProps {
	readonly?: boolean
	value?: string
	onChange?: (value: string) => void,
	disableCopyPaste?: boolean,
	sendPasteNotification: () => void,
}

const Editor: React.FunctionComponent<EditorProps> = (props) => {
	const {
		value: defaultValue,
		readonly, onChange,
		sendPasteNotification,
		disableCopyPaste,
	} = props;
	const [textCopied, setTextCopied] = useState<string>('');
	const [copyToken, setTokenValue] = useState<string>('');
	const editorRef = useRef<ReactQuill | null>(null);

	const handleCopy = useCallback(() => {
		if (!editorRef.current) return;
		const currentValue = editorRef.current.getEditor().getText();
		const selection = editorRef.current.getEditor().getSelection();
		if (!selection) return;
		const valueToSet = currentValue.slice(selection.index, selection.length);
		const token = crypto.randomUUID();
		setTokenValue(token);
		navigator.clipboard.writeText(token);
		setTextCopied(valueToSet);
	}, [setTextCopied, setTokenValue]);

	const handlePaste = useCallback(async () => {
		if (!editorRef.current) return;
		const token = await navigator.clipboard.readText();
		if (!token || token !== copyToken) {
			// handle Copy Error Herer
			message.error('Attention: Please do not use copy paste as it may result in disqualification.');
			sendPasteNotification();
			return;
		}

		if (editorRef.current) {
			const textToPaste = textCopied;
			const selection = editorRef.current.getEditor().getSelection();
			if (!selection) return;
			console.log(selection);
			const previousValue = editorRef.current.getEditor().getText();
			editorRef.current.getEditor().deleteText(selection.index, selection.length);
			editorRef.current.getEditor().insertText(selection.index, textCopied);
			editorRef.current.getEditor().setSelection({ index: selection.index, length: 0 });
		}
	}, [textCopied, copyToken, sendPasteNotification]);

	const handleKeyDownEvent = useCallback((ev: any) => {
		if (disableCopyPaste) {
			if ('key' in ev && typeof ev.key === 'string' && 'ctrlKey' in ev && ev.ctrlKey === true) {
				if (ev.key === 'v') {
					ev?.preventDefault();
					handlePaste();
				}
				if (ev.key === 'c') {
					ev?.preventDefault();
					handleCopy();
				}
			}
		}
	}, [handlePaste, handleCopy, disableCopyPaste]);

	return (
		<ReactQuill
			ref={editorRef}
			readOnly={readonly}
			theme="snow"
			defaultValue={defaultValue}
			onChange={onChange}
			onKeyDown={(ev) => handleKeyDownEvent(ev)}
		/>
	);
};

interface SubjectiveFormValues {
	answer: string
	upldFile: any
}

interface zipFileProps {
	name: string;
	size: number;
	type: 'dir' | 'file';
}
interface TreeNode {
	key: string;
	title: React.ReactNode;
	size?: number;
	type: 'dir' | 'file';
	children: TreeNode[];
}

interface AttemptSubjectiveProps {
	questionData: AttemptQuestionData
	disableCopyPaste?: boolean
	submitting: boolean
	onSubmit: (data: any) => Promise<void>
	setAttempt: (attemptData: unknown) => void
	getAttempt: () => any | null,
	sendPasteNotification: () => void
}

export const AttemptSubjective: React.FunctionComponent<AttemptSubjectiveProps> = (props) => {
	const {
		questionData,
		onSubmit,
		setAttempt,
		getAttempt,
		submitting: isSubmitting,
		disableCopyPaste,
		sendPasteNotification,
	} = props;
	const [form] = Form.useForm();
	const [zipView, setZipView] = useState<Array<TreeNode>>([]);
	const [mendatoryZipPreview, setMendatoryZipPreview] = useState<Array<TreeNode>>([]);
	const [ZipPreview, setZipPreview] = useState<TreeNode | null>(null);
	const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

	const saveAttempt = useCallback(() => {
		setAttempt({
			value: form.getFieldValue('answer'),
		});
	}, [form, setAttempt]);

	const handleSubjectiveFormSubmit = useCallback(async (values: SubjectiveFormValues) => {
		saveAttempt();
		const files: Array<any> = [];
		values?.upldFile?.forEach((file, index) => {
			let key = 'upldFile';
			if (index !== 0) {
				key += `${index}`;
			}
			if (file?.originFileObj) {
				files.push(file.originFileObj);
			}
		});
		await onSubmit({
			txtSub: values.answer || '',
			upldFile: files,
		});
	}, [onSubmit, saveAttempt]);

	const createSizeString = (size: number) => {
		if (size >= 1024 * 1024) {
			return `${(size / (1024 * 1024)).toFixed(2)}MB`;
		}
		return `${(size / 1024).toFixed(2)}KB`;
	};

	const populateTreeNode = useCallback((
		item: zipFileProps, objectMap: { [key: string]: TreeNode },
	) => {
		if (!item.name || objectMap[item.name]) {
			return objectMap[item.name];
		}
		const fileNameArray = item.name.split('/').filter(Boolean);
		const fileName = fileNameArray.pop();
		if (!fileName) {
			console.trace('File name is not present.', item);
			return null;
		}
		const title = (
			<span style={{ fontWeight: 'bold', color: item.type === 'dir' ? 'blue' : 'green' }}>
				<span style={{ fontSize: '14px' }}>
					{`${fileName.substring(0, 40)}${fileName.length > 40 ? '...' : ''}`}
				</span>
				<span style={{ fontSize: '12px', color: '#888' }}>{item.type !== 'dir' ? `(${createSizeString(item.size)})` : ''}</span>
			</span>
		);
		const isRootElement = fileNameArray.length === 0;
		if (isRootElement) {
			// eslint-disable-next-line no-param-reassign
			objectMap[item.name] = {
				key: item.name,
				type: item.type,
				children: [],
				title,
			};
			return objectMap[item.name];
		}
		const parentDir = `${fileNameArray.join('/')}/`;
		const parentDirObj = populateTreeNode({
			name: parentDir,
			size: 0,
			type: 'dir',
		}, objectMap);

		const node: TreeNode = {
			children: [],
			key: item.name,
			type: item.type,
			size: item.size,
			title,
		};
		if (item.type === 'file') {
			parentDirObj?.children.unshift(node);
		} else {
			parentDirObj?.children.push(node);
		}
		// eslint-disable-next-line no-param-reassign
		objectMap[item.name] = node;
		return node;
	}, []);

	const generateAntdTreeData = useCallback((items: zipFileProps[]): TreeNode | null => {
		let tree: TreeNode | null = null;
		const map: Record<string, TreeNode> = {};
		items.forEach((item, index) => {
			const result = populateTreeNode(item, map);
			if (index === 0) {
				tree = result;
			}
		});
		return tree;
	}, [populateTreeNode]);

	const appendZipFile = useCallback(async (file: any) => {
		try {
			const fileReader = new FileReader();
			const data = await new Promise<zipFileProps[]>((resolve, reject) => {
				try {
					fileReader.onload = async () => {
						const zip = await JSZip.loadAsync(fileReader?.result as ArrayBuffer);
						const zipFiles: zipFileProps[] = [];
						const filePromises: Promise<void>[] = [];
						const parentName = file.name.replace('.zip', '/');
						zipFiles.push({
							name: parentName,
							size: 0,
							type: 'dir',
						});
						Object.entries(zip.files).forEach(([_, zipEntry]) => {
							const filePromise = zipEntry.async('blob').then((fileContent) => {
								const zipEntryName = `${parentName}${zipEntry.name}`;
								zipFiles.push({
									name: zipEntryName,
									size: fileContent.size,
									type: zipEntry.dir ? 'dir' : 'file',
								});
							});
							filePromises.push(filePromise);
						});
						await Promise.all(filePromises);
						resolve(zipFiles);
					};
					fileReader.onerror = (error) => reject(error);
					fileReader.readAsArrayBuffer(file.originFileObj);
				} catch (error) {
					console.error(error);
				}
			});
			console.log(data);
			const treeView = generateAntdTreeData(data);
			if (!treeView) {
				return;
			}
			setZipView((view) => [...view, treeView]);
			setMendatoryZipPreview((value) => [...value, treeView]);
		} catch (error) {
			console.error(error);
		}
	}, [generateAntdTreeData]);

	const normFile = useCallback((e: any, ...arg: any) => {
		const file = e?.file;
		let toInclude = true;
		const ALLOWED_NUMBER_OF_FILES = 5;
		const SIZE_LIMIT = 2 * 1024 * 1024;
		const TOTAL_SIZE_LIMIT = 10 * 1024 * 1024;
		if (!file) {
			return e?.fileList;
		}

		if ((e?.fileList?.filter((ele) => ele.name === file.name)).length > 1) {
			toInclude = false;
		}

		if (e?.file?.uid === e?.fileList?.[e?.fileList?.length - 1]?.uid) {
			let showMessageForFileSizeExceeded = false;
			let showFileLengthMessage = false;
			if (e.fileList.length > ALLOWED_NUMBER_OF_FILES) {
				e.fileList = e.fileList.slice(0, ALLOWED_NUMBER_OF_FILES);
				showFileLengthMessage = true;
			}
			const setOfNamesAppended = new Set<string>();
			const uniqueFiles = e?.fileList?.filter((ele) => {
				let toAppend = !setOfNamesAppended.has(ele.name ?? '');
				if (ele?.size > SIZE_LIMIT) {
					toAppend = false;
					showMessageForFileSizeExceeded = true;
					return false;
				}
				if (ele.name) {
					setOfNamesAppended.add(ele.name);
				}
				return toAppend;
			});
			e.fileList = uniqueFiles;

			let totolCurrentSizeExcludingThisFile = 0;
			// eslint-disable-next-line no-restricted-syntax
			e.fileList = e.fileList.filter((cFile) => {
				if (totolCurrentSizeExcludingThisFile > TOTAL_SIZE_LIMIT) {
					return false;
				}
				if (cFile?.size) {
					totolCurrentSizeExcludingThisFile += cFile?.size ?? 0;
				}
				return true;
			});
			if (showFileLengthMessage) {
				message.error(`Number of files should not exceed ${ALLOWED_NUMBER_OF_FILES}.`);
			} else if (totolCurrentSizeExcludingThisFile > TOTAL_SIZE_LIMIT) {
				message.error('Total upload size should not exceed then 2MB.');
			} else if (showMessageForFileSizeExceeded) {
				message.error('File size should not exceed 2MB.');
			}
		}
		if (!toInclude) {
			const filteredFile = e?.fileList?.filter((ele) => ele.uid !== file.uid) ?? [];
			return filteredFile;
		}
		if (file.status !== 'removed') {
			const fileThatIsInserted = e?.fileList?.filter((ele) => ele.uid === file.uid)?.[0];
			if (fileThatIsInserted
				&& fileThatIsInserted.type
				&& typeOfZipFormat.includes(fileThatIsInserted.type)
			) {
				appendZipFile(fileThatIsInserted);
			}
		}
		return e?.fileList ?? [];
	}, [appendZipFile]);

	useEffect(() => {
		const intervalId = setInterval(saveAttempt, 2000);

		return () => clearInterval(intervalId);
	}, [saveAttempt]);

	const onExpand = (keys: React.Key[]) => {
		setExpandedKeys(keys as string[]);
	};

	const handleModalCancel = () => {
		const fileList = form.getFieldValue('upldFile') || [];
		const mandatoryZip = mendatoryZipPreview[0];
		form.setFieldsValue({
			upldFile: fileList.filter((file: File) => file.name.replace('.zip', '') !== mandatoryZip.key.slice(0, -1)),
		});
		setMendatoryZipPreview((view) => view.slice(1));
	};

	const zipClicked = (file: UploadFile<any>) => {
		const { name } = file;
		const key = `${name.split('.').slice(0, -1).join('.')}/`;
		zipView.forEach((node) => {
			if (node.key === key) {
				setZipPreview(node);
			}
		});
	};

	return (
		<div className="attempt-subjective-container">
			<div>
				<span className="info-text">Answer below:</span>
				<Divider style={{ margin: '0.5rem 0 1.5rem 0', width: '50%', minWidth: 'unset' }} />
				<div>
					<Form
						form={form}
						onFinish={handleSubjectiveFormSubmit}
					>
						<Form.Item
							name="answer"
							initialValue={(
								getAttempt()?.value
								|| questionData.questionTypeSubjective?.lastSubmitted
							)}
						>
							<Editor
								readonly={isSubmitting}
								disableCopyPaste
								sendPasteNotification={sendPasteNotification}
							/>
						</Form.Item>
						{
							questionData?.questionTypeSubjective?.isFileUpload && (
								<Form.Item
									name="upldFile"
									valuePropName="fileList"
									getValueFromEvent={normFile}
									className="upload-list-subjective-2bb4fd1f-5830-496f-9161-b84f32888557"
								>
									<Upload
										beforeUpload={() => false}
										multiple
										itemRender={(originNode, file, fileList, actions) => (
											<div
												style={{
													display: 'flex',
													justifyContent: 'space-between',
													alignItems: 'center',
												}}
											>
												<Button
													style={{
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
													}}
													type="link"
													onClick={() => zipClicked(file)}
												>
													<span
														style={{
															fontWeight: 'bold',
															textOverflow: 'ellipsis',
															overflow: 'hidden',
															whiteSpace: 'nowrap',
															maxWidth: '300px',
														}}
													>
														{file.name}
													</span>
													<span
														style={{
															fontWeight: 'bold',
															fontSize: '12px',
															color: '#888',
															paddingInlineStart: '4px',
														}}
													>
														{file.size
															&& `(${createSizeString(file.size)})`}
													</span>
												</Button>
												<Button onClick={actions.remove} type="link" icon={<CloseCircleFilled />} />
											</div>
										)}
									>
										<Button icon={<UploadOutlined />}>Select file to upload</Button>
									</Upload>
								</Form.Item>
							)
						}
					</Form>
				</div>
			</div>
			<div className="submit-subjective-container">
				<Button
					type="primary"
					size="large"
					onClick={() => form.submit()}
					loading={isSubmitting}
				>
					submit
				</Button>
			</div>
			<Modal
				title={`Files present in ${mendatoryZipPreview.length ? mendatoryZipPreview[0].key.slice(0, -1) : ''}`}
				open={mendatoryZipPreview.length > 0}
				onCancel={handleModalCancel}
				onOk={() => setMendatoryZipPreview((value) => value.slice(1))}
				closable={false}
				style={{ width: 600 }}
				bodyStyle={{ height: 'auto', maxHeight: 400, overflow: 'auto' }}
			>
				<Tree
					showLine={{ showLeafIcon: false }}
					selectable={false}
					treeData={mendatoryZipPreview.length ? mendatoryZipPreview.slice(0, 1) : []}
					expandedKeys={expandedKeys}
					onExpand={onExpand}
					expandAction="click"
				/>
			</Modal>

			<Modal
				title={`Files present in ${ZipPreview ? ZipPreview.key.slice(0, -1) : ''}`}
				open={!!ZipPreview}
				onCancel={() => setZipPreview(null)}
				onOk={() => setZipPreview(null)}
				closable={false}
				style={{ width: 600 }}
				bodyStyle={{ height: 'auto', maxHeight: 400, overflow: 'auto' }}
			>
				<Tree
					showLine={{ showLeafIcon: false }}
					selectable={false}
					treeData={ZipPreview ? [ZipPreview].slice(0, 1) : []}
					expandedKeys={expandedKeys}
					onExpand={onExpand}
					expandAction="click"
				/>
			</Modal>
		</div>
	);
};
